import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import PatchHeader from '../components/PatchHeader';
export const _frontmatter = {
  "title": "Polish Makes Perfect",
  "date": "2019-03-04",
  "promo": "serviceworkies",
  "description": "Overcome acclimation & practice the art of polish",
  "color": "#d1593a"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Your work is undoubtedly awesome. You're proud of it. But deep down you know it could be `}<em parentName="p">{`so much better`}</em>{`. You struggle to know how to take your craft to the next level.`}</p>
    <p>{`The good news is you don't need a degree in Human Computer Interaction or to have been born with some magic designer DNA. You can `}<strong parentName="p">{`practice`}</strong>{` the art of polish and `}<strong parentName="p">{`develop`}</strong>{` the ability to create highly polished work.`}</p>
    <p><em parentName="p">{`What`}</em>{` you polish should be heavily influenced by your `}<a parentName="p" {...{
        "href": "/post/what-to-do-with-user-feedback/"
      }}>{`user's feedback`}</a>{`, or you may polish something nobody even cares about.`}</p>
    <p><em parentName="p">{`When`}</em>{` you polish needs to take place at `}<a parentName="p" {...{
        "href": "/post/polish"
      }}>{`very specific times`}</a>{` or you may ship junk or worse — never ship at all.`}</p>
    <h2>{`How to Polish`}</h2>
    <p>{`You have an enormous blind spot. As a developer you've spent hundreds of hours thinking about and working on your project. Your main obstacle isn't a lack of skill or a shortage of time, it's an abundance of `}<strong parentName="p">{`familiarity`}</strong>{`.`}</p>
    <p>{`As developers we quickly become blind to the lame parts of our apps. We acclimate faster than a sheep grows thick wool in the bitter winter. We get used to the confusing menus, UI glitches, and fragile user interactions we've written.`}</p>
    <p>{`The way to combat acclimation is to practice self-awareness. Regularly ask yourself these questions:`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`What have I gotten used to?`}</em></li>
      <li parentName="ol"><em parentName="li">{`What workarounds have I been doing, that could trip up someone using this for the first time?`}</em></li>
      <li parentName="ol"><em parentName="li">{`How can I completely eliminate the need for these workarounds?`}</em></li>
    </ol>
    <p>{`It's a `}<a parentName="p" {...{
        "href": "/post/polish"
      }}>{`constant process`}</a>{`. Bake it into your routine/schedule/sprint. The little things add up. And you'll get better at it the more you practice.`}</p>
    <PatchHeader game="serviceworkies" type="polish" mdxType="PatchHeader">
  Polish Examples
    </PatchHeader>
    <p>{`That's the principle, now I want to show you some practical examples of how my team and I polish — using `}<a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{` as an example. My games are certainly not perfect, but with every bit of polish they get better and better. We grab a handful of small polish tasks every week as we work. Polish is part of every release. But after shipping `}<a parentName="p" {...{
        "href": "/post/service-workies-chapter2/"
      }}>{`chapter two`}</a>{` we set out to do a dedicated round of polish.`}</p>
    <h3>{`Dialogue Positioning`}</h3>
    <p>{`One of the things I wanted to polish in this game over `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` and `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` was the dialogue placement. In both of those games the dialogue had a fixed position docked to one side of the screen:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/gc-chat.jpg",
        "alt": "separate chat panel"
      }}></img></p>
    <p>{`This pattern came up in user feedback — people often spent the majority of their time looking at the left side of the screen: either reading dialogue or typing code. They'd sometimes miss the helpful visualizations. A lot of games do this for dialogue (probably because it's easy), but I wanted to try for better this time. So in Service Workies the dialogue is positioned above characters' heads as they speak:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-dialogue-1.jpg",
        "alt": "service workies dialogue"
      }}></img></p>
    <p>{`This made the experience that much more immersive. But it also created a problem: sometimes the dialogue would cover up important game elements:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-dialogue-2.jpg",
        "alt": "service workies dialogue covered up"
      }}></img></p>
    <p>{`My "quick fix" was to make the dialogue element not emit click events using CSS `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events"
      }}>{`pointer-events: none`}</a>{`. So users can just click through the dialogue element to the underlying game canvas. It worked and I got used to it. But in hindsight this is a `}<em parentName="p">{`super lame`}</em>{` experience.`}</p>
    <p>{`After an internal battle on the subject of shipping quickly vs being lazy, I went back to the drawing board on dialogue positioning. I explored a few ideas like "slots" where dialogue could be guaranteed to not cover anything. But this game has so many characters & beasts that can all move around on screen, there was no such lucky quick fix.`}</p>
    <p>{`That's when my teamie/brother `}<a parentName="p" {...{
        "href": "https://twitter.com/j_gds"
      }}>{`Jonathan`}</a>{` came up with this brilliant idea: a smart positioning system based on `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Simulated_annealing"
      }}>{`simulated annealing`}</a>{` (inspired by the process of liquid cooling/crystallizing to solid). Each game object is given a penalty score for being covered, and the dialogue is given a handful of attempts to figure out the most ideal position (lowest penalty score) as it "cools" into place.`}</p>
    <p>{`The whole process is nearly instant and only renders once. But so that you can see it in action here it is artificially throttled and forcing a re-render on each calculation. Check it out:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/sw-smartdialogue.gif",
        "alt": "service workies smart dialogue"
      }}></img></p>
    <p>{`The dialogue element now lands in the perfect position without covering anything up!`}</p>
    <h3>{`Code Sidebar`}</h3>
    <p>{`The game has a code panel that players use to practice writing Service Worker code.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-sidebar-1.jpg",
        "alt": "service workies dedicated sidebar"
      }}></img></p>
    <p>{`It looks pretty nice if you're on a large external monitor like I'm used to working on. But on laptops or smaller screens it forces the game to shrink to `}<em parentName="p">{`tiny`}</em>{`, unplayable sizes:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-sidebar-2.jpg",
        "alt": "service workies dedicated sidebar ewwww"
      }}></img></p>
    <p>{`Not to mention wastes a ton of space when the code panel isn't being used.`}</p>
    <p>{`The polish for this one was to dock the code panel off screen until needed, giving full space for the story elements of the game:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-sidebar-3.jpg",
        "alt": "service workies collapsed sidebar"
      }}></img></p>
    <p>{`And sliding the code panel in whenever it's needed:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-sidebar-4.jpg",
        "alt": "service workies collapsed sidebar"
      }}></img></p>
    <p>{`This experience is so much better, especially for smaller screens!`}</p>
    <h3>{`Asset Loading`}</h3>
    <p>{`Keeping load times small is hard enough for a regular web app. It's an even bigger challenge for a web-based game with tons of heavy assets. I realized I'd gotten used to the long loading times, which only would have gotten worse as I add a bunch more chapters each with their own artwork & sound effects.`}</p>
    <p>{`I cracked open the Network tab in Chrome and counted 241 requests just for the game's MP3s — 32.8MB. Ouch!`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-network-1.jpg",
        "alt": "service workies network tab"
      }}></img></p>
    <p>{`The polish for this was actually pretty easy. I added some logic to my level loader to look at all the characters being used in the current level, and automatically fetch only the assets needed for that level. So when you're fighting the Crow in chapter two you don't have to pay the cost of downloading chapter 3's Spider assets. I then keep a reference to any assets loaded so they aren't fetched more than once.`}</p>
    <p>{`This helped a bunch but the game was still making a ton of requests for little mp3's.`}</p>
    <p>{`Next I tried out a technique called `}<a parentName="p" {...{
        "href": "https://pixijs.io/pixi-sound/examples/sprites.html"
      }}>{`audio sprites`}</a>{` which means you combine a bunch of little files into one big file and have markers into it for the individual sounds. This made a huge difference — took it down to just 6 requests and 8.4MB.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-network-2.jpg",
        "alt": "service workies network tab"
      }}></img></p>
    <p>{`I'll take this performance idea even further with Service Worker itself in a future round of polish and share how it goes.`}</p>
    <h3>{`Keyboard Shortcuts`}</h3>
    <p>{`All my games including Service Workies have a keyboard shortcut as an alternative to the Next button for advancing dialogue: `}<inlineCode parentName="p">{`cmd+enter`}</inlineCode>{` on macOS, `}<inlineCode parentName="p">{`ctrl+enter`}</inlineCode>{` on Windows/Linux. I often get requests for this feature even though it already exists, because users had no way to discover it on their own.`}</p>
    <p>{`A lot of apps like Github have `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/github/getting-started-with-github/keyboard-shortcuts"
      }}>{`one central place`}</a>{` where they list all keyboard shortcuts. I don't know about you but I never bother to look those up. I'd much rather have shortcuts discoverable in context.`}</p>
    <p>{`So here's what I did instead: when you hover long enough over a button that has a keyboard shortcut, its shortkey appears.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-tooltip-1.jpg",
        "alt": "service workies tooltip for keyboard shortcuts"
      }}></img></p>
    <p>{`I'm a fan of this pattern and will probably use it more often. But I look forward to seeing how my users like it!`}</p>
    <h3>{`Other Bits o' Polish`}</h3>
    <p>{`For completeness here are the other things that got some polish love:`}</p>
    <ul>
      <li parentName="ul">{`The Portal now shows a snapshot of the Page code it last loaded`}</li>
      <li parentName="ul">{`Escape keyboard shortcut for closing the code panel and Level/Volume menus`}</li>
      <li parentName="ul">{`Select sound effect`}</li>
      <li parentName="ul">{`Locked level screen instead of just a redirect`}</li>
      <li parentName="ul">{`Hover/cursor polish in the levels menu`}</li>
      <li parentName="ul">{`Rename "game volume" to "sound effects volume"`}</li>
      <li parentName="ul">{`50% CPU reduction thanks to audio sprites and smarter React state handling — WOOOO!`}</li>
      <li parentName="ul">{`Use regex for certain code matching`}</li>
      <li parentName="ul">{`Fine tune death animation timing`}</li>
      <li parentName="ul">{`Fade back to regular music after boss levels`}</li>
      <li parentName="ul">{`Make battle music loop while fighting`}</li>
    </ul>
    <h2>{`Polish Makes Perfect`}</h2>
    <p>{`Acclimation is the biggest barrier to polish. As you work you've got to keep an eye out for things you've learned to live with that others won't be willing to. Identify and remove the workarounds. Don't get discouraged or compare your work to anyone else. Just practice the art of polish — one little thing after another. Before long your skills and products will start to shine.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      